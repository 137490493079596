import * as React from "react";
import {useEffect, useState} from "react";
import Layout from "../../components/Navigation/layout";
import {Client, handleApiError} from "../../services/ApiService";
import {formHeading, headline} from './index.module.scss';
import * as style from "../../components/StripeErrorBanner/StripeErrorBanner.module.scss";
import {Link} from "gatsby";



const NewsLetterPage = () => {

    return (
        <Layout pageTitle="Newsletter">
            <div className={"container py-5"}>
                <h2 className={formHeading}>Newsletter Bestätigt!</h2>
                <div className={headline}>Sie können die Seite nun verlassen</div>
                <div className={headline}>
                <Link to={"/"} target={"_blank"} className={headline}>
                    Zurück zu FairStärkt
                </Link>
                </div>
            </div>
        </Layout>
    )
}

export default NewsLetterPage;
